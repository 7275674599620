<template>
  <section class="relative w-full aspect-video max-h-[660px] items-center justify-center flex flex-col space-y-4 lg:space-y-12 py-10 md:py-20 px-5">
    <div class="z-0">
      <img class="absolute bottom-0 inset-0 object-cover top-0 left-0 right-0 h-full w-full max-w-none z-0" src="~/assets/img/home/footer.jpg" loading="lazy" alt="Create inspiring worlds." />
      <div class="z-10 absolute bottom-0 inset-0 bg-gradient-to-t from-black-900/60 to-black-900/30" />
    </div>
    <h2 class="text-4xl lg:text-6xl font-bold text-lightgray-100 text-center z-10 text-shadow">
      Create inspiring worlds<span class="text-orange-500">.</span>
    </h2>
    <div class="text-center space-y-2 w-full max-w-2xl m-auto z-10 text-shadow">
      <p class="text-lg lg:text-xl text-lightgray-100 font-medium text-shadow">
        Give your  team access to the world’s leading photo reference library and inspire the world with award-winning work.
      </p>
    </div>
    <a href="https://mattepaint.com/gallery/" class="z-10 rounded-lg bg-orange-500 shadow-sm cursor-pointer hover:bg-orange-400 text-white font-bold py-4 px-6 flex w-full md:w-auto justify-center uppercase tracking-widest hover:shadow-lgsm">
      Explore the Gallery
    </a>
  </section>
</template>